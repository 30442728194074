import { css } from 'styled-components'

export const breakpoints = {
  sm: 768,
  spc: 1024,
  xxl: 1064,
  xxxl: 2560,
}

export const deviceLayout = {
  xxxlLayout: (...args) => css`
    @media (min-width: ${breakpoints.xxxl}px) {
      ${css(...args)};
    }
  `,
  xxlLayout: (...args) => css`
    @media (min-width: ${breakpoints.xxl}px) {
      ${css(...args)};
    }
  `,
  spcLayout: (...args) => css`
    @media (min-width: ${breakpoints.spc}px) {
      ${css(...args)};
    }
  `,
  pcLayout: (...args) => css`
    @media (min-width: ${breakpoints.sm}px) {
      ${css(...args)};
    }
  `,
  spLayout: (...args) => css`
    @media (max-width: ${breakpoints.sm - 1}px) {
      ${css(...args)};
    }
  `,
}
