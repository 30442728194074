import { useMemo } from 'react'

import { LOCALE } from '@hhgtech/hhg-components/types'
import { ExtendedNextRouter } from 'interfaces/types'
import { useRouter } from 'next/router'

export function useRouterExtend(): ExtendedNextRouter & {
  router: ExtendedNextRouter
} {
  const router = useRouter()
  const res = useMemo(() => {
    const newRouter = {
      ...router,
      locale: (router.locale as LOCALE) || LOCALE.Vietnam,
    }
    return {
      ...newRouter,
      router: newRouter,
    }
  }, [router])
  return res
}
