import { useState, useEffect } from 'react'

const getScreenWidth = () => {
  if (typeof window === 'undefined') return undefined
  return window.innerWidth
}

const getIsMobile = () => {
  return typeof window !== 'undefined' && window.innerWidth < 768
}

const getIsLargeMobile = () => {
  return (
    typeof window !== 'undefined' &&
    window.innerWidth >= 768 &&
    window.innerWidth < 1112
  )
}

const getIsTablet = () => {
  return (
    typeof window !== 'undefined' &&
    window.innerWidth > 768 &&
    window.innerWidth < 1025
  )
}

const useScreenSize = () => {
  const [screenWidth, setScreenWidth] = useState<undefined | number>(
    getScreenWidth,
  )
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const [isLargeMobile, setIsLargeMobile] = useState(getIsLargeMobile())
  const [isTablet, setIsTablet] = useState(getIsTablet())

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(getScreenWidth())
      setIsMobile(getIsMobile())
      setIsLargeMobile(getIsLargeMobile())
      setIsTablet(getIsTablet())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    isMobile,
    isLargeMobile,
    isTablet,
    screenWidth,
  }
}

export { useScreenSize }
