import { MediaQueries } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${MediaQueries.mbDown} {
    padding: 0 16px;
  }

  ${MediaQueries.mbUp} {
    max-width: 1064px;
  }
`

export const SectionContainerStyled = styled.section`
  width: 100%;
  margin: 0 auto;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`
