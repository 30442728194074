export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_IS_MOBILE_SSR: 'SET_IS_MOBILE_SSR',
  ADD_BOOKMARKS: 'ADD_BOOKMARKS',
  REMOVE_BOOKMARKS: 'REMOVE_BOOKMARKS',
  SET_OPEN_LOGOUT_POPUP: 'SET_OPEN_LOGOUT_POPUP',
  SET_COMMENT_FOCUS: 'SET_COMMENT_FOCUS',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  SET_CLOSE_SITEWIDE_BANNER: 'SET_CLOSE_SITEWIDE_BANNER',
}
