import { CommonGAssets } from '@hhgtech/hhg-components/gAssets'
import { formatCategory } from 'api/dataTransform'
import { Category, CategoryApi } from 'interfaces/types'

export const addFrisoCategory = (categories: Category[]) => {
  if (
    process.env.NEXT_PUBLIC_FRISO_CATEGORY_PERMALINK &&
    process.env.NEXT_PUBLIC_FRISO_CATEGORY_AFTER_CATEGORY_ID &&
    categories.filter(
      (i) =>
        String(i.termId) ===
        process.env.NEXT_PUBLIC_FRISO_CATEGORY_AFTER_CATEGORY_ID,
    ).length > 0
  ) {
    let frisoCategoryIndex = 0
    frisoCategoryIndex =
      categories.findIndex(
        (i) =>
          String(i.termId) ===
          process.env.NEXT_PUBLIC_FRISO_CATEGORY_AFTER_CATEGORY_ID,
      ) + 1
    return [
      ...categories.slice(0, frisoCategoryIndex),
      formatCategory({
        icon: CommonGAssets.getAssetPath('friso/mb-friso-category.png'),
        name: 'Tiêu Hoá 0-2 tuổi',
        cat_name: 'Tiêu Hoá 0-2 tuổi',
        permalink: process.env.NEXT_PUBLIC_FRISO_CATEGORY_PERMALINK,
      } as CategoryApi),
      ...categories.slice(frisoCategoryIndex),
    ]
  }
  return categories
}
