import { ReactNode, CSSProperties, ReactHTML } from 'react'

import { StyledContainer } from './index.styled'

export type Props = {
  children?: ReactNode
  className?: string
  style?: CSSProperties
  as?: keyof ReactHTML
}

const Container = ({ children, className, style, as }: Props) => {
  return (
    <StyledContainer className={className} style={style} as={as}>
      {children}
    </StyledContainer>
  )
}

export { Container }
