import { getMarryBabyUrl } from 'api/utils'
import { useRouterExtend } from 'hooks/useRouterExtend'
import { basePath } from 'interfaces/constants'

export function useCurrentUrl() {
  const {
    locale = 'vi-VN',
    router: { asPath },
  } = useRouterExtend()
  return `${getMarryBabyUrl(locale)}${basePath}${asPath}`
}
