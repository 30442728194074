import React, { useContext } from 'react'

import Cookies from 'js-cookie'
import { actionTypes } from 'state/actionTypes'
import { AppContext } from 'state/appContext'

import {
  StyledsiteWideContainer,
  StyledIcon,
  StyledCloseIconWrapper,
} from './index.styled'
export type Props = {
  icon: string
  content: string
  link: string
  isMobile?: boolean
  isCollapse?: boolean
  type: 'article' | 'category' | 'home' | 'other'
}

const SiteWide = ({
  icon,
  content,
  link,
  isCollapse,
  isMobile,
  type,
}: Props) => {
  const { state, dispatch } = useContext(AppContext)
  const { sitewideClose } = state
  const handleCLoseSitewide = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    Cookies.set(`discover-disable-sitewide-${type}`, 'true')
    dispatch({
      type: actionTypes.SET_CLOSE_SITEWIDE_BANNER,
      payload: {
        type,
      },
    })
  }

  return (
    <StyledsiteWideContainer
      data-is-mobile={isMobile}
      data-is-collapse={isCollapse || sitewideClose?.[type]}
      className="no-replace-click"
      href={link}
      data-event-category="Header Banner"
      data-event-action="Link Click"
      data-event-label={link}
    >
      {icon ? (
        <StyledIcon>
          <div className="image-wrapper">
            <img loading="lazy" src={icon} className="icon" />
          </div>
        </StyledIcon>
      ) : null}
      {content ? (
        <span
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : null}
      <StyledCloseIconWrapper onClick={handleCLoseSitewide}>
        <img
          loading="lazy"
          src="/images/close.svg"
          data-event-category="Header Banner"
          data-event-action="Close Click"
          data-vars-event-label={link}
          className="close-icon"
        />
      </StyledCloseIconWrapper>
    </StyledsiteWideContainer>
  )
}

export { SiteWide }
