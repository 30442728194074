import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyledsiteWideContainer = styled.a`
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  text-decoration: none;
  background-color: #ffeaf1;
  background-position: center;
  background-size: cover;
  transition: height 0.3s;

  &[data-is-mobile='true'] {
    justify-content: flex-start;
  }
  &[data-is-mobile='false'] {
    justify-content: center;
  }

  &[data-is-collapse='false'] {
    height: 64px;
  }
  &[data-is-collapse='true'] {
    height: 0;
  }

  .content {
    display: -webkit-box;
    overflow: hidden;
    padding-right: 32px;
    -webkit-box-orient: vertical;
    color: #252626;
    font-size: 14px;
    font-weight: 600;
    -webkit-line-clamp: 2;
    line-height: 24px;
    text-overflow: ellipsis;

    ${deviceLayout.pcLayout`
      font-size: 16px;
    `}

    a {
      color: #2561ad;
    }
  }
`

export const StyledCloseIconWrapper = styled.div`
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  border-radius: 50%;
  cursor: pointer;

  margin-right: 16px;
  padding: 10px;
  height: 40px;
  width: 40px;

  .close-icon {
    width: 20px;
    height: 20px;
  }
`

export const StyledIcon = styled.div`
  max-width: 100px;
  margin-right: 16px;
  .image-wrapper {
    display: inline-block;
    overflow: hidden;
    max-width: 100px;
    img {
      width: auto;
      height: 64px;
    }
  }
`
