import { ParsedUrlQuery } from 'querystring'

import React, { ReactNode, useContext } from 'react'

import {
  Footer,
  NavigationV2 as HHGNavigationLayout,
  NavigationV2Props,
  NavMenuContentType,
  SeoHead,
} from '@hhgtech/hhg-components'
import { TranslationsProvider } from '@hhgtech/hhg-components/i18n'
import { FooterInfoMarryBaby } from '@hhgtech/hhg-components/types'
import { SEOData as SEODataType } from 'components/layout'
import { SiteWide } from 'components/molecules/siteWide'
import { useGetIsMobile, useScreenSize } from 'hooks'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { LOCALE_SPECS } from 'interfaces/constants'
import { SiteWideBanner, LocaleType, Category } from 'interfaces/types'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { actionTypes } from 'state/actionTypes'
import { AppContext } from 'state/appContext'
import { addFrisoCategory } from 'utils/common'

import { State } from '../../../state/types'

export type AmpNextArtice = {
  articleOne?: {
    title: string
    image: string
    link: string
  }
  articleTwo?: {
    title: string
    image: string
    link: string
  }
  articleThree?: {
    title: string
    image: string
    link: string
  }
}

export type Props = {
  isMobile?: boolean
  title?: string
  description?: string
  image?: string
  children?: ReactNode
  onTopBarAppears?: (b: boolean) => void
  isSearchBarDisplay?: boolean
  isOpened?: boolean
  className?: string
  SEOData?: SEODataType
  isStatic?: boolean
  type?: 'article' | 'website' | 'profile'
  ampNextArticle?: AmpNextArtice
  isSponsored?: boolean
  isHomePage?: boolean
  // sponsoredType?: 'article' | 'content'
  // sponsoredBy?: ContentSponsor[] | ContentSponsor
  sitewideBanner?: SiteWideBanner | null
  logo?: JSX.Element
  forcedCanonicalUrl?: string
  dataNavMenuContent?: NavMenuContentType | null
  activeMenuService?: NavigationV2Props['activeMenuService']
  footerMenu?: FooterInfoMarryBaby
}

const NavigatorLayout = ({
  title,
  description,
  image,
  children,
  onTopBarAppears,
  SEOData = {},
  type = 'website',
  isMobile: _isMobile,
  footerMenu,
  forcedCanonicalUrl,
  sitewideBanner,
  dataNavMenuContent,
  activeMenuService,
}: Props) => {
  const router = useRouter()
  const { locale = 'vi-VN', query, pathname } = router as {
    locale: LocaleType
    defaultLocale: LocaleType
    query: ParsedUrlQuery
    asPath: string
    pathname: string
  }

  const { state = {} as State, dispatch } = useContext(AppContext)
  const { isMobileSsr, sitewideClose, userInfo } = state

  const { isMobile: isMobileCsr } = useScreenSize()
  const isMobile =
    isMobileSsr !== undefined &&
    isMobileCsr !== undefined &&
    useGetIsMobile(isMobileSsr, isMobileCsr) === undefined
      ? _isMobile
      : useGetIsMobile(isMobileSsr, isMobileCsr)

  const siteName = LOCALE_SPECS[locale || 'vi-VN'].SITE_NAME_FORMATTED

  // const isAmp = useAmp()
  const currentUrl = useCurrentUrl()

  return (
    <TranslationsProvider locale={'vi-VN_MB'}>
      <HHGNavigationLayout
        siteType="marryBaby"
        projectType="marryBaby"
        // isAmp={isAmp}
        locale={locale as LocaleType}
        onLogout={() => {
          dispatch({
            type: actionTypes.LOGOUT,
            payload: {
              domain: '.marrybaby.vn',
            },
          })
        }}
        ssrMenuContent={{
          ...dataNavMenuContent,
          categories: {
            ...dataNavMenuContent?.categories,
            items: addFrisoCategory(
              (dataNavMenuContent?.categories?.items || []) as Category[],
            ),
          },
        }}
        onTopBarAppears={(b: boolean) => {
          onTopBarAppears && onTopBarAppears(b)
        }}
        userState={{ ...state, userInfo: userInfo || undefined } as any}
        isMobile={isMobile}
        activeMenuService={activeMenuService || 'categories'}
        currentUrl={currentUrl}
        sitewideBanner={
          sitewideBanner ? (
            <SiteWide
              isMobile={isMobile}
              icon={sitewideBanner?.icon ?? ''}
              content={sitewideBanner?.content ?? ''}
              type={sitewideBanner?.type}
              link={sitewideBanner?.link || ''}
            />
          ) : undefined
        }
        sitewideBannerShown={
          !!sitewideBanner && !sitewideClose?.[sitewideBanner?.type]
        }
        languageOptions={undefined}
        isUserLoading={state?.userInfo === undefined}
      />
      <SeoHead
        SEOData={{
          SEOTitle: title,
          SEOImage: image,
          SEODescription: description,
          siteName,
          seoType: type,
          ...SEOData,
        }}
        // isAmp={isAmp}
        locale={locale as LocaleType}
        routerPathname={pathname}
        routerQuery={query as { [key: string]: string }}
        nextHead={({ children: headChildren }) => {
          return (
            <Head>
              {headChildren}
              {/* <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5590101971803078"
                crossOrigin="anonymous"
              /> */}
            </Head>
          )
        }}
        siteType="marryBaby"
        userId={userInfo?.id}
        forcedCanonicalUrl={forcedCanonicalUrl}
      />
      {children}
      <Footer
        footerInfo={footerMenu as FooterInfoMarryBaby}
        locale={locale as LocaleType}
        siteType="marryBaby"
        type="main"
        basePath={''}
        // isAmp={false}
      />
    </TranslationsProvider>
  )
}

export { NavigatorLayout }
