/** @type {{[key: string]: string}} */
const domainLocales = {
  'vi-VN': 'marrybaby.vn',
  //   'id-ID': 'hellosehat.com',
  //   'ms-MY': 'hellodoktor.com',
  //   'km-KH': 'hellokrupet.com',
  //   'vi-VN': 'hellodoctor.com.ph',
  //   'th-TH': 'hellokhunmor.com',
  //   'my-MM': 'hellosayarwon.com',
  //   'zh-TW': 'helloyishi.com.tw',
  //   'hi-IN': 'helloswasthya.com',
}

const ignoreList = []

module.exports = {
  domainLocales,
  ignoreList,
}
